<template>
  <v-data-table
    v-bind="$attrs"
    :headers="headers"
    :items="tableItems"
    :loading="isFindServicePending"
    :options.sync="options"
    hide-default-footer
  >
    <!-- pass through normal slots -->
    <template v-for="(_, name) in $slots" #[name]>
      <slot :name="name" />
    </template>

    <!-- pass through scoped slots -->
    <template v-for="(_, scopedName) in $scopedSlots" #[scopedName]="slotData">
      <slot :name="scopedName" v-bind="slotData" />
    </template>

    <template #footer>
      <v-pagination
        v-if="pageCount > 1"
        v-model="options.page"
        :length="pageCount"
        :total-visible="paginationTotalVisible"
      ></v-pagination>
    </template>
  </v-data-table>
</template>

<script>
import { makeFindMixin } from "feathers-vuex";
import deepmerge from "deepmerge";

export default {
  name: "FeathersServiceTable",
  components: {},
  mixins: [
    makeFindMixin({
      service() {
        return this.service;
      },
      watch: true,
    }),
  ],
  props: {
    service: {
      type: String,
      required: true,
    },
    params: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    headers: {
      type: Array,
      required: true,
    },
    limit: {
      type: Number,
      required: false,
      default: 50,
    },
    itemsPerPage: {
      type: Number,
      required: false,
      default: 10,
    },
    paginationTotalVisible: {
      type: Number,
      required: false,
      default: 8,
    },
    autoRefresh: {
      type: Number,
      required: false,
      default: null,
    },
    useClonedItems: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      options: {
        page: 1,
        itemsPerPage: this.itemsPerPage,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
    };
  },
  computed: {
    skip() {
      return this.limit * (this.options.page - 1);
    },
    pageCount() {
      if (!this.servicePaginationData.default) return 1;
      const { total } = this.servicePaginationData.default.mostRecent;
      const pages = Math.ceil(total / this.itemsPerPage);
      return pages;
    },
    serviceFetchParams() {
      const { sortBy, sortDesc } = this.options;
      const $sort = sortBy.reduce((acc, el, idx) => {
        acc[el] = sortDesc[idx] ? -1 : 1;
        return acc;
      }, {});
      const pagination = {
        query: {
          $sort,
          $limit: this.limit,
          $skip: this.skip,
        },
        debounce: 500,
      };
      const merge = deepmerge(this.params, pagination);
      return merge;
    },
    serviceParams() {
      const pagination = {
        query: {},
      };
      const merge = deepmerge(this.params, pagination);
      return merge; // merge;
    },
    clonedItems() {
      return this.items.map((item) => ({
        id: item.id,
        old: item,
        new: item.clone(),
      }));
    },
    tableItems() {
      return this.useClonedItems ? this.clonedItems : this.items;
    },
  },
  watch: {},
  beforeCreate() {},
  created() {
    if (this.autoRefresh) {
      this.refreshService();
    }
  },
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {
    clearTimeout(this.refresh);
  },
  destroyed() {},
  methods: {
    refreshService() {
      console.debug(`refresh ${this.service}`);
      this.findService();
      this.refresh = setTimeout(this.refreshService, this.autoRefresh * 1000);
    },
  },
};
</script>

<style></style>
